import React from "react"
import { withTranslation } from "react-i18next";
import facebook_icon from "../images/Facebook_nontransparent.svg"
import instagram_icon from "../images/Instagram_nontransparent.svg"
import linkedin_icon from "../images/Linkedin_nontransparent.svg"
import youtube_icon from "../images/Youtube_nontransparent.svg"

const Footer = ({t}) => {
  return (
    <footer className="d-flex justify-content-center flex-column footer overflow-hidden">
      <div className="io mb-2">
        © {new Date().getFullYear()} { t("footerCopy.copy") }
      </div>
      <div className="">
        <a href="https://www.facebook.com/InsureOnline.comInc" target="_blank" rel="noreferrer"><img className="pr-2" src={facebook_icon}  alt="InsureOnline.com Facebook"/></a>
        <a href="https://www.instagram.com/insureonline.com_/" target="_blank" rel="noreferrer"><img className="pr-2" src={instagram_icon} alt="InsureOnline.com Instagram"/></a>
        <a href="https://www.linkedin.com/company/insureonline/" target="_blank" rel="noreferrer"><img className="pr-2" src={linkedin_icon} alt="InsureOnline.com Linkedin"/></a>
        <a href="https://www.youtube.com/channel/UCYeBQ5eRTJJ9B0bHNWpiGtg" target="_blank" rel="noreferrer"><img src={youtube_icon} alt="InsureOnline.com YouTube"/></a>
      </div>
    </footer>
  )
}

export default withTranslation(["translation"])(Footer)
